import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "base",
      meta: {
        auth: true,
      },
      redirect: "/histories-live",
      component: () => import("@/layout/Index.vue"),
      children: [
        {
          path: "checkpoints",
          name: "checkpoints",
          component: () =>
            import("@/views/pages/checkpoints/CheckpointsPage.vue"),
        },
        {
          path: "devices",
          name: "devices",
          component: () => import("@/views/pages/devices/DevicesPage.vue"),
        },
        {
          path: "users",
          name: "users",
          component: () => import("@/views/pages/users/UsersPage.vue"),
        },
        {
          path: "people",
          name: "people",
          component: () => import("@/views/pages/people/PeoplePage.vue"),
        },
        {
          path: "person/create",
          name: "person-create",
          component: () => import("@/views/pages/people/PersonFormPage.vue"),
        },
        {
          path: "person/:id/edit",
          name: "person-edit",
          component: () => import("@/views/pages/people/PersonFormPage.vue"),
        },
        {
          path: "person/:id/detail",
          name: "person-detail",
          component: () => import("@/views/pages/people/PersonDetailPage.vue"),
        },
        {
          path: "histories",
          name: "histories",
          component: () => import("@/views/pages/histories/HistoriesPage.vue"),
        },
        {
          path: "histories-live",
          name: "histories-live",
          component: () =>
            import("@/views/pages/histories/HistoriesLivePage.vue"),
        },
        {
          path: "roles",
          name: "roles",
          component: () => import("@/views/pages/roles/RolesPage.vue"),
        },
        {
          path: "settings",
          name: "settings",
          component: () => import("@/views/pages/settings/SettingsPage.vue"),
        },
        {
          path: "queries",
          name: "queries",
          component: () => import("@/views/pages/queries/QueriesPage.vue"),
        },
      ],
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/pages/LoginPage.vue"),
    },
  ],
});

Vue.router = router;

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch("changeThemeLoadingState", true);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  store.dispatch("changeThemeLoadingState", false);
});

export default router;
