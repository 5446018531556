import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

const { i18n } = Vue;

const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");
const theme = JSON.parse(localStorage.getItem("theme"));

const preset = {
  breakpoint: {
    mobileBreakpoint: 1264,
    scrollBarWidth: 16,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920,
    },
  },
  icons: {
    iconfont: "mdi",
    values: {},
  },
  lang: {
    current: "en",
    locales: {
      en: {
        badge: "Badge",
        close: "Close",
        dataIterator: {
          noResultsText: i18n.t("no_matching_records_found"),
          loadingText: i18n.t("loading_items") + "...",
        },
        dataTable: {
          ariaLabel: {
            sortDescending: "Sorted descending.",
            sortAscending: "Sorted ascending.",
            sortNone: "Not sorted.",
            activateNone: "Activate to remove sorting.",
            activateDescending: "Activate to sort descending.",
            activateAscending: "Activate to sort ascending.",
          },
          itemsPerPageText: i18n.t("rows_per_page") + ":",
          sortBy: i18n.t("sort_by"),
        },
        dataFooter: {
          itemsPerPageText: i18n.t("items_per_page") + ":",
          itemsPerPageAll: i18n.t("all"),
          nextPage: i18n.t("next_page"),
          prevPage: i18n.t("previous_page"),
          firstPage: i18n.t("first_page"),
          lastPage: i18n.t("last_page"),
          pageText: "{0}-{1} / {2}",
          page: i18n.t("page"),
          show: i18n.t("show"),
        },
        datePicker: {
          itemsSelected: "{0} selected",
          nextMonthAriaLabel: "Next month",
          nextYearAriaLabel: "Next year",
          prevMonthAriaLabel: "Previous month",
          prevYearAriaLabel: "Previous year",
        },
        noDataText: i18n.t("no_data_available"),
        carousel: {
          prev: "Previous visual",
          next: "Next visual",
          ariaLabel: {
            delimiter: "Carousel slide {0} of {1}",
          },
        },
        calendar: {
          moreEvents: "{0} more",
        },
        fileInput: {
          counter: "{0} files",
          counterSize: "{0} files ({1} in total)",
        },
        timePicker: {
          am: "AM",
          pm: "PM",
        },
        pagination: {
          ariaLabel: {
            wrapper: "Pagination Navigation",
            next: "Next page",
            previous: "Previous page",
            page: "Goto Page {0}",
            currentPage: "Current Page, Page {0}",
          },
        },
        rating: {
          ariaLabel: {
            icon: "Rating {0} of {1}",
          },
        },
      },
    },
  },
  rtl: false,
  theme: {
    dark: theme === null ? mediaQueryList.matches : theme.dark,
    default: "light",
    disable: false,
    options: {
      customProperties: true,
      variations: true,
    },
    themes: {
      light: {
        primary: {
          base: "#397ED7",
          lighten3: "#9CBFEB",
          lighten2: "#6A9FE1",
          lighten1: "#397ED7",
          darken1: "#064CA4",
          darken2: "#04397B",
          darken3: "#032652",
          accent1: "#82b1ff",
          accent2: "#448aff",
          accent3: "#2979ff",
          accent4: "#2962ff",
        },
        secondary: "#304156",
        accent: "#82B1FF",
        error: "#FF5252",
        info: {
          base: "#5c6bc0",
          lighten5: "#e8eaf6",
          lighten4: "#c5cae9",
          lighten3: "#9fa8da",
          lighten2: "#7986cb",
          lighten1: "#5c6bc0",
          darken1: "#3949ab",
          darken2: "#303f9f",
          darken3: "#283593",
          darken4: "#1a237e",
          accent1: "#8c9eff",
          accent2: "#536dfe",
          accent3: "#3d5afe",
          accent4: "#304ffe",
        },
        success: {
          base: "#66bb6a",
          lighten5: "#e8f5e9",
          lighten4: "#c8e6c9",
          lighten3: "#a5d6a7",
          lighten2: "#81c784",
          lighten1: "#66bb6a",
          darken1: "#43a047",
          darken2: "#388e3c",
          darken3: "#2e7d32",
          darken4: "#1b5e20",
          accent1: "#b9f6ca",
          accent2: "#69f0ae",
          accent3: "#00e676",
          accent4: "#00c853",
        },
        warning: {
          base: "#ff7d54",
          lighten5: "#fbe9e7",
          lighten4: "#ffccbc",
          lighten3: "#ffab91",
          lighten2: "#ff8a65",
          lighten1: "#ff7043",
          darken1: "#f4511e",
          darken2: "#e64a19",
          darken3: "#d84315",
          darken4: "#bf360c",
          accent1: "#ff9e80",
          accent2: "#ff6e40",
          accent3: "#ff3d00",
          accent4: "#dd2c00",
        },
        danger: {
          base: "#ef5350",
          lighten5: "#ffebee",
          lighten4: "#ffcdd2",
          lighten3: "#ef9a9a",
          lighten2: "#e57373",
          lighten1: "#ef5350",
          darken1: "#e53935",
          darken2: "#d32f2f",
          darken3: "#c62828",
          darken4: "#b71c1c",
          accent1: "#ff8a80",
          accent2: "#ff5252",
          accent3: "#ff1744",
          accent4: "#d50000",
        },
        dark: "#161D31",
        background: "#F5F5F5",
      },
      dark: {
        primary: {
          base: "#397ED7",
          lighten3: "#9CBFEB",
          lighten2: "#6A9FE1",
          lighten1: "#397ED7",
          darken1: "#064CA4",
          darken2: "#04397B",
          darken3: "#032652",
          accent1: "#82b1ff",
          accent2: "#448aff",
          accent3: "#2979ff",
          accent4: "#2962ff",
        },
        secondary: "#304156",
        accent: "#FF4081",
        error: "#FF5252",
        info: {
          base: "#5c6bc0",
          lighten5: "#e8eaf6",
          lighten4: "#c5cae9",
          lighten3: "#9fa8da",
          lighten2: "#7986cb",
          lighten1: "#5c6bc0",
          darken1: "#3949ab",
          darken2: "#303f9f",
          darken3: "#283593",
          darken4: "#1a237e",
          accent1: "#8c9eff",
          accent2: "#536dfe",
          accent3: "#3d5afe",
          accent4: "#304ffe",
        },
        success: {
          base: "#66bb6a",
          lighten5: "#e8f5e9",
          lighten4: "#c8e6c9",
          lighten3: "#a5d6a7",
          lighten2: "#81c784",
          lighten1: "#66bb6a",
          darken1: "#43a047",
          darken2: "#388e3c",
          darken3: "#2e7d32",
          darken4: "#1b5e20",
          accent1: "#b9f6ca",
          accent2: "#69f0ae",
          accent3: "#00e676",
          accent4: "#00c853",
        },
        warning: {
          base: "#ff7d54",
          lighten5: "#fbe9e7",
          lighten4: "#ffccbc",
          lighten3: "#ffab91",
          lighten2: "#ff8a65",
          lighten1: "#ff7043",
          darken1: "#f4511e",
          darken2: "#e64a19",
          darken3: "#d84315",
          darken4: "#bf360c",
          accent1: "#ff9e80",
          accent2: "#ff6e40",
          accent3: "#ff3d00",
          accent4: "#dd2c00",
        },
        danger: {
          base: "#ef5350",
          lighten5: "#ffebee",
          lighten4: "#ffcdd2",
          lighten3: "#ef9a9a",
          lighten2: "#e57373",
          lighten1: "#ef5350",
          darken1: "#e53935",
          darken2: "#d32f2f",
          darken3: "#c62828",
          darken4: "#b71c1c",
          accent1: "#ff8a80",
          accent2: "#ff5252",
          accent3: "#ff1744",
          accent4: "#d50000",
        },
        dark: "#323A51",
        background: "#161D31",
      },
    },
  },
};

export default new Vuetify(preset);
