import Vue from "vue";

import axios_ from "axios";
import VueAxios from "vue-axios";

import i18n from "@/lang";
import { generateUrl } from "@/helpers";

export const axios = axios_.create({
  baseURL: generateUrl(),
});

axios.interceptors.request.use(
  function (config) {
    config.baseURL = generateUrl();
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    if (error.message.indexOf("Network Error") > -1) {
      Vue.$toast.warning(i18n.t("network_error"));
    } else if (error.message.indexOf("timeout") > -1) {
      Vue.$toast.warning(i18n.t("request_timeout"));
    }

    return Promise.reject(error);
  }
);

export default {
  install(Vue) {
    Vue.axios = axios;
    Vue.use(VueAxios, axios);
  },
};
